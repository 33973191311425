<script setup lang="ts">
const props = defineProps<{
	color: 'yellow' | 'black' | 'white'
}>()

// const bgClass = 'bg-transparent'
</script>

<template>
	<div class="pill" :class="`pill--${props.color}`">
		<slot />
	</div>
</template>

<style>
.pill {
	@apply inline-block  rounded-full  leading-none px-[1em] pt-[0.7em] pb-[0.5em] font-semibold text-[11px] sm:text-[13px];
}

.pill--yellow {
	@apply bg-nemo-yellow text-black;
}

.pill--white {
	@apply bg-transparent text-white border;
}

.pill--black {
	@apply bg-transparent text-black border border-[#333];
}
</style>
